import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
import Code from '../components/code';
export const _frontmatter = {
  "title": "State Machines in React",
  "date": "2019-08-30",
  "promo": "grids",
  "description": "Why state machines are awesome and how to use them in React",
  "color": "#a73375"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    {
      /* prettier-ignore */
    }
    {
      /*
       PAIN: state management, unexpected states/bugs
       SOLUTION: XState + react like so
       LINKS:
         - service workies: show kolohe
         - how items flow into a grid
         - shiny new grid tools
         - polish makes perfect?
      
       examples:
        starter:
        https://codesandbox.io/s/state-machines-in-react-tr39w?fontsize=14&module=%2Fsrc%2FMenu.jsx
          almost there minus the services:
         https://codesandbox.io/s/state-machines-in-react-zko2k
          final:
         https://codesandbox.io/s/state-machines-in-react-hrz40
        * state machines are dope
      * useful for my games (promo)
      * useful for general UI
      * brain mindset
      * build a menu and splain it
      * in the react component I use useMachine and define the actions
      * the file contains everything that’s related:
      * the state machine
      * the actions it can perform (plan JS functions)
      * the invoke promises it can call (plain JS functions)
      * any guards
      * the react component
       * thx to David
      * model based testing?
       */
    }
    <p>{`One of the biggest pain points when developing an app is the tricky business of managing state. Many bugs are caused by things getting into unexpected states, or by race conditions. Finite state machines can help eliminate both types of bugs entirely, while providing a welcome, structured way to build components.`}</p>
    <h2>{`When to use a State Machine?`}</h2>
    <p>{`I build `}<a parentName="p" {...{
        "href": "https://mastery.games"
      }}>{`my education games`}</a>{` using a ton of separate little state machines. Every single level is its own state machine, allowing complex flows like fail/tryagain/hint states. My characters are state machines.`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/kolohe-idle.gif",
        "alt": "Kolohe game character"
      }}></img></p>
    <p>{`But I've found state machines to be an excellent fit for regular UI components as well. Menus, Panes, Dialogues, Buttons, you name it. State machines are perfect for them all.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/statemachines/ui-components.jpg",
        "alt": "UI components"
      }}></img></p>
    <p>{`These days the excellent `}<a parentName="p" {...{
        "href": "https://xstate.js.org/docs/"
      }}>{`XState library`}</a>{` makes using state machines on the web easy.`}</p>
    <h2>{`State Machine Components`}</h2>
    <p>{`I like to organize most of my code as React components. The component is such a perfect unit of abstraction. But as `}<a parentName="p" {...{
        "href": "https://twitter.com/DavidKPiano"
      }}>{`David Khourshid`}</a>{` pointed out, every React component is actually an `}<em parentName="p">{`"implicit state machine"`}</em>{` — cobbled together based on the component's spread out logic. Components on their own are pretty awesome. But a component driven by an `}<strong parentName="p">{`explicit`}</strong>{` state machine is even better.`}</p>
    <h3>{`The Brain`}</h3>
    <p><img parentName="p" {...{
        "src": "/img/statemachines/brain.jpg",
        "alt": "state machine brain"
      }}></img></p>
    <p><strong parentName="p">{`The state machine is the brain`}</strong>{` of the component. Its jobs are to:`}</p>
    <ul>
      <li parentName="ul">{`define all the possible states the component can be in`}</li>
      <li parentName="ul">{`define all allowed transitions between states`}</li>
      <li parentName="ul">{`list any actions (side effects) that can happen, and exactly when they should happen`}</li>
    </ul>
    <h3>{`The Body`}</h3>
    <p><img parentName="p" {...{
        "src": "/img/statemachines/body.jpg",
        "alt": "React component body"
      }}></img></p>
    <p><strong parentName="p">{`The React component is the body`}</strong>{`. It `}<em parentName="p">{`reacts`}</em>{` to its state machine brain and:`}</p>
    <ul>
      <li parentName="ul">{`implements the actions/services/effects that the brain wants to take place`}</li>
      <li parentName="ul">{`renders a UI based on the state machine's current value`}</li>
      <li parentName="ul">{`causes the brain to take new transitions to new states, often in response to user interactions`}</li>
    </ul>
    <h2>{`Let's Build One!`}</h2>
    <p>{`Let's create a `}<inlineCode parentName="p">{`Menu`}</inlineCode>{` state machine component similar to the one in `}<a parentName="p" {...{
        "href": "https://serviceworkies.com"
      }}>{`Service Workies`}</a>{`.`}</p>
    <h3>{`Layout`}</h3>
    <p>{`To start I've whipped up a fairly standard React component, structured it using the fantastic CSS Grid (which BTW `}<em parentName="p">{`you`}</em>{` too can master easily by playing `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{`).`}</p>
    <p>{`You can `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/state-machines-in-react-tr39w?fontsize=14&module=%2Fsrc%2FMenu.jsx"
      }}>{`edit this layout on codesandbox`}</a>{` if you'd like. It looks like this:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/statemachines/layout.jpg",
        "alt": "menu CSS grid layout "
      }}></img></p>
    <h3>{`Define the Brain`}</h3>
    <p>{`Now let's give this thing a state machine brain. To do that we brainstorm all the possible states this component can be in. You might think "oh there's just two states — open and closed". But not so! We're going to be animating the Menu open and closed, so we actually have `}<strong parentName="p">{`four`}</strong>{` states: `}<inlineCode parentName="p">{`open`}</inlineCode>{`, `}<inlineCode parentName="p">{`opening`}</inlineCode>{`, `}<inlineCode parentName="p">{`closed`}</inlineCode>{`, and `}<inlineCode parentName="p">{`closing`}</inlineCode>{`.`}</p>
    <h4>{`Wait Why Not Just Use a Boolean?`}</h4>
    <p>{`Being explicit about all our states lets us avoid bugs and conditional logic soup like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if (!isOpen && isAnimating && isActive) {
  // do a thing based on boolean combo
}
`}</code></pre>
    <p>{`Using state machines instead of booleans also prevents the component from being in `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=IcgmSRJHu_8&feature=youtu.be"
      }}>{`impossible states`}</a>{` such as `}<inlineCode parentName="p">{`isOpen`}</inlineCode>{` being `}<inlineCode parentName="p">{`false`}</inlineCode>{` and `}<inlineCode parentName="p">{`isAnimating`}</inlineCode>{` being `}<inlineCode parentName="p">{`true`}</inlineCode>{` at the same time. What would happen if we tried to animate the Menu even though it's currently closed? That'd be a bug! The world has too many Jira tickets as it is. Using booleans for state can be problematic, see `}<a parentName="p" {...{
        "href": "https://twitter.com/geddski/status/1168976900552822784"
      }}>{`this thread`}</a>{` for more details.`}</p>
    <p>{`Alright so here's the start of our state machine that simply lists all the possible states and which one is the starting state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const menuMachine = Machine({
  initial: 'closed',
  states: {
    closed: {},
    opening: {},
    open: {},
    closing: {},
  },
})
`}</code></pre>
    <p>{`Now we let our state machine know about the state transitions we want to allow. From the `}<inlineCode parentName="p">{`closed`}</inlineCode>{` state we want to go to `}<inlineCode parentName="p">{`opening`}</inlineCode>{`. From `}<inlineCode parentName="p">{`open`}</inlineCode>{` we want to go to `}<inlineCode parentName="p">{`closing`}</inlineCode>{`. Let's also allow the sliding states to be cancellable by allowing `}<inlineCode parentName="p">{`opening`}</inlineCode>{` to go to `}<inlineCode parentName="p">{`closing`}</inlineCode>{` and vice versa.`}</p>
    <Code theme="light" lang="js" highlight={[{
      from: 5,
      to: 7
    }, {
      from: 10,
      to: 12
    }, {
      from: 15,
      to: 17
    }, {
      from: 20,
      to: 22
    }]} mdxType="Code">
  {`const menuMachine = Machine({
  initial: 'closed',
  states: {
    closed: {
      on: {
        OPEN: 'opening',
      },
    },
    opening: {
      on: {
        CLOSE: 'closing',
      },
    },
    open: {
      on: {
        CLOSE: 'closing',
      },
    },
    closing: {
      on: {
        OPEN: 'opening',
      },
    },
  },
})`}
    </Code>
    <p>{`Our state machine is almost done. The last thing we need to do is define some side effects that we want to happen during the `}<inlineCode parentName="p">{`opening`}</inlineCode>{` and `}<inlineCode parentName="p">{`closing`}</inlineCode>{` states.`}</p>
    <p>{`We'll invoke some `}<a parentName="p" {...{
        "href": "https://xstate.js.org/docs/guides/communication.html#invoking-promises"
      }}>{`promise services`}</a>{` (functions that return a promise) for these side effects, and transition to `}<inlineCode parentName="p">{`open`}</inlineCode>{` or `}<inlineCode parentName="p">{`closed`}</inlineCode>{` states when the sliding animation completes.`}</p>
    <Code theme="light" lang="js" highlight={[{
      from: 10,
      to: 13
    }, {
      from: 24,
      to: 27
    }]} mdxType="Code">
  
  {`const menuMachine = Machine({
  initial: "closed",
  states: {
    closed: {
      on: {
        OPEN: "opening",
      },
    },
    opening: {
      invoke: {
        src: "openMenu",
        onDone: { target: "open" },
      },
      on: {
        CLOSE: "closing",
      },
    },
    open: {
      on: {
        CLOSE: "closing",
      },
    },
    closing: {
      invoke: {
        src: "closeMenu",
        onDone: { target: "closed" },
      },
      on: {
        OPEN: "opening",
      },
    },
  },
})`}
    </Code>
    <p>{`And that's it! ALL of our logic is done and it's `}<strong parentName="p">{`rock solid`}</strong>{`. No unexpected states are even possible.`}</p>
    <p>{`XState has an `}<a parentName="p" {...{
        "href": "https://xstate.js.org/viz/"
      }}>{`awesome visualizer`}</a>{` where you can both see and play with your state machine definitions. Here's what this one looks like:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/statemachines/state-machine-viz.jpg",
        "alt": "state machine visualization"
      }}></img></p>
    <p>{`At a glance we can instantly know a ton of things about the logic of our component:`}</p>
    <ul>
      <li parentName="ul">{`the state begins as `}<inlineCode parentName="li">{`closed`}</inlineCode></li>
      <li parentName="ul">{`from `}<inlineCode parentName="li">{`closed`}</inlineCode>{` it can only go to the `}<inlineCode parentName="li">{`opening`}</inlineCode>{` state (not to the `}<inlineCode parentName="li">{`open`}</inlineCode>{` state directly).`}</li>
      <li parentName="ul">{`when in `}<inlineCode parentName="li">{`closing`}</inlineCode>{` state a side effect named `}<inlineCode parentName="li">{`closeMenu`}</inlineCode>{` will be invoked`}</li>
      <li parentName="ul">{`when in `}<inlineCode parentName="li">{`opening`}</inlineCode>{` state a side effect named `}<inlineCode parentName="li">{`openMenu`}</inlineCode>{` will be invoked`}</li>
      <li parentName="ul">{`when the side effects (animations) are finished, the machine will transition automatically to the appropriate `}<inlineCode parentName="li">{`open`}</inlineCode>{` or `}<inlineCode parentName="li">{`closed`}</inlineCode>{` state.`}</li>
      <li parentName="ul">{`both the `}<inlineCode parentName="li">{`opening`}</inlineCode>{` and `}<inlineCode parentName="li">{`closing`}</inlineCode>{` states are cancellable. (the Menu could animate open partway but then be told to animate back closed again).`}</li>
    </ul>
    <p>{`Here is our `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/state-machines-in-react-mx2b1?fontsize=14&module=%2Fsrc%2FMenu.jsx"
      }}>{`Menu component demo`}</a>{` now with a brain. But it's not very useful yet until we give it a React component body.`}</p>
    <h3>{`Implement the Body`}</h3>
    <p>{`Our brain needs a body to control. Here's our basic React component so far:`}</p>
    <Code theme="light" lang="js" content={`export const Menu = () => {
  let label = "open"\n
  return (
    <div>
      <Button
        onClick={() => { }} >
        {label}
      </Button>
    </div>
  );
};`} mdxType="Code" />
    <p>{`Let's wire up our state machine to it, using the official `}<inlineCode parentName="p">{`useMachine`}</inlineCode>{` hook in the `}<a parentName="p" {...{
        "href": "https://github.com/davidkpiano/xstate/tree/master/packages/xstate-react"
      }}>{`@xstate/react`}</a>{` package.`}</p>
    <Code theme="light" lang="js" highlight={[{
      from: 2,
      to: 2
    }]} content={`export const Menu = () => {
  const [current, send] = useMachine(menuMachine);\n
  let label = "open"\n
  return (
    <div>
      <Button
        onClick={() => { }} >
        {label}
      </Button>
    </div>
  );
};`} mdxType="Code" />
    <p>{`This gives us the `}<inlineCode parentName="p">{`current`}</inlineCode>{` state node that represents the state machine's current state, and a `}<inlineCode parentName="p">{`send`}</inlineCode>{` function for when we want to tell the machine to transition to new states.`}</p>
    <p>{`Now let's render what we want based on the current state, and wire up the button to send the right message for transitioning to the next state.`}</p>
    <Code theme="light" lang="js" highlight={[{
      from: 3,
      to: 7
    }, {
      from: 14,
      to: 14
    }, {
      from: 16,
      to: 16
    }]} content={`export const Menu = () => {
  const [current, send] = useMachine(menuMachine);\n
  const nextMessage =
    current.matches("open") || current.matches("opening") ? "CLOSE" : "OPEN";\n
  let label = nextMessage === "OPEN" ? "open" : "close";\n
  return (
    <div>
      <Button
        onClick={() => { 
          // cause a transition to a new state
          send(nextMessage);
        }} >
        {label}
      </Button>
    </div>
  );
};`} mdxType="Code" />
    <p>{`Sweet, now our button label is accurate, and clicking the button will transition our state machine to the right state. Feel free to play with `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/state-machines-in-react-zko2k?fontsize=14&module=%2Fsrc%2FMenu.jsx"
      }}>{`the demo`}</a>{` up to this point.`}</p>
    <p>{`The only thing we're missing now is to implement those side effects our state machine wants to invoke. We'll do the following:`}</p>
    <ul>
      <li parentName="ul">{`use a `}<a parentName="li" {...{
          "href": "https://reactjs.org/docs/hooks-reference.html#useref"
        }}>{`ref`}</a>{` so we can animate the Menu div`}</li>
      <li parentName="ul">{`implement each action using the `}<a parentName="li" {...{
          "href": "https://reactjs.org/docs/hooks-reference.html#usecallback"
        }}>{`useCallback`}</a>{` hook for good performance and to ensure that our ref is always up to date.`}</li>
      <li parentName="ul">{`use `}<a parentName="li" {...{
          "href": "https://greensock.com/gsap/"
        }}>{`Greensock`}</a>{` for the animations`}</li>
      <li parentName="ul">{`our actions will return a promise that resolves when the animation is done`}</li>
      <li parentName="ul">{`configure our state machine to use our actions`}</li>
    </ul>
    <p>{`It sounds like a lot but it's actually pretty straightforward!`}</p>
    <Code theme="light" lang="js" highlight={[{
      from: 2,
      to: 2
    }, {
      from: 7,
      to: 19
    }, {
      from: 21,
      to: 33
    }, {
      from: 39,
      to: 42
    }]} content={`export const Menu = () => {
  const element = useRef();\n
  // services the machine can "invoke".
  // useCallback ensures that our services always using the latest props/state/refs
  // so long as we add them as deps.
  const openMenu = useCallback(
    (context, event) => {
      return new Promise(resolve => {
        gsap.to(element.current, {
          duration: 0.5,
          x: 0,
          backdropFilter: "blur(2px)",
          ease: Elastic.easeOut.config(1, 1),
          onComplete: resolve
        });
      });
    },
    [element]
  );\n
  const closeMenu = useCallback(
    (context, event) => {
      return new Promise(resolve => {
        gsap.to(element.current, {
          duration: 0.5,
          x: -290,
          backdropFilter: "blur(0px)",
          ease: Elastic.easeOut.config(1, 1),
          onComplete: resolve
        });
      });
    },
    [element]
  );\n
  const [current, send] = useMachine(menuMachine, {
    // configure the machine's services.
    // these have to return a promise for XState to know when to
    // take the onDone transtiion
    services: {
      openMenu,
      closeMenu
    }
  });\n
  const nextMessage =
    current.matches("open") || current.matches("opening") ? "CLOSE" : "OPEN";\n
  let label = nextMessage === "OPEN" ? "open" : "close";\n
  return (
    <div ref={element}>
      <Button
        onClick={() => { 
          // cause a transition to a new state
          send(nextMessage);
        }} >
        {label}
      </Button>
    </div>
  );
};`} mdxType="Code" />
    <p>{`And that's it! A fully functioning React component body controlled by our state machine brain.`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/state-machine-demo.gif",
        "alt": "state machine demo"
      }}></img></p>
    <p>{`Check out the `}<a parentName="p" {...{
        "href": "https://codesandbox.io/s/state-machines-in-react-hrz40?fontsize=14&module=%2Fsrc%2FMenu.jsx"
      }}>{`full demo`}</a>{`!`}</p>
    <p>{`A little bonus tip: keep the state machine component's brain and its body collocated in the same file (`}<inlineCode parentName="p">{`Menu.jsx`}</inlineCode>{`). Goes down smooth.`}</p>
    <h2>{`State Machine Components FTW`}</h2>
    <p>{`State machines are awesome and can eliminate entire classes of bugs. State machine components are rock solid and can give you a lot of confidence in your component. As a bonus, you can `}<a parentName="p" {...{
        "href": "https://gedd.ski/post/learn-to-draw/"
      }}>{`think through`}</a>{` all the logic with a pencil & paper before writing a single line of code.`}</p>
    <p>{`And once you're using state machines you can skip writing manual tests with `}<a parentName="p" {...{
        "href": "https://css-tricks.com/model-based-testing-in-react-with-state-machines/"
      }}>{`model based testing`}</a>{`. Prepare to have your mind blown.`}</p>
    <p>{`Special thanks to `}<a parentName="p" {...{
        "href": "https://twitter.com/DavidKPiano"
      }}>{`David`}</a>{` for building `}<a parentName="p" {...{
        "href": "https://xstate.js.org/docs/"
      }}>{`XState`}</a>{` and for reviewing this post.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      